import PropTypes from "prop-types"
import React from "react"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"

import Page from "pages"

// Activating fake backend
// fakeBackend()
const Alldata = JSON.parse(localStorage.getItem("dataJ"))

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// init firebase backend
// initFirebaseBackend(firebaseConfig)
import firebase from "firebase/app"
import "firebase/analytics"
firebase.initializeApp(firebaseConfig)
const analytics = firebase.analytics()
// analytics.logEvent('select_content', {
//   content_type: 'image',
//   content_id: 'P12453',
//   items: [{ name: 'Kittens' }]
// });
const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {Alldata
            ? authRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  objct={route.objct}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))
            : location.reload()}

          {Alldata
            ? userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  objct={route.objct}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))
            : location.reload()}

          {Alldata
            ? Object.keys(Alldata.data).map((route, idx) => (
                <Authmiddleware
                  path={"/page/" + route}
                  layout={Layout}
                  objct={Alldata.data[route]}
                  component={Page}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))
            : location.reload()}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
