import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "../datatables.scss"

import "./gratitude.css"
import SweetAlert from "react-bootstrap-sweetalert"
import classnames from "classnames"
import * as htmlToImage from "html-to-image"
import download from "downloadjs"
import GratitudeTemplate from "components/Templates/GratitudeTemplate"
const Alldata = JSON.parse(localStorage.getItem("dataJ"))

const Gratitude = () => {
  const [ActiveTab, setActiveTab] = useState("Send Gratitude")
  const [activeTabContent, setactiveTabContent] = useState("1")
  const obj = JSON.parse(localStorage.getItem("authUser"))
  const [moda, setmoda] = useState(false)
  const [Gratmoda, setGratmoda] = useState(false)
  const [recvEmail, setrecvEmail] = useState("")
  const [recvName, setrecvName] = useState("")
  const [RecievedEmail, setRecievedEmail] = useState("")
  const [RecievedName, setRecievedName] = useState("")
  const [RecievedMessage, setRecievedMessage] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [gratstatus, setgratstatus] = useState(false)
  const [templateURL, settemplateURL] = useState("1")
  // const [templateURL, settemplateURL] = useState("https://images.indusexperienceday.com/t1.png")
  const [recvtemplateURL, setrecvtemplateURL] = useState("")
  const userObj = obj

  const loader = document.querySelector(".loader")
  const [Alluser, setAlluser] = useState([])
  const [GratArr, setGratArr] = useState([])
  useEffect(() => {
    setTimeout(() => {
      if (false) {
        CometChatWidget.init({
          appID: "208110919cfd1f73",
          appRegion: "us",
          authKey: "6664e1d4a16e6922c424f5cf3a4a6251206467bd",
        }).then(
          response => {
            /**
             * Create user once initialization is successful
             */
            // const obj1 = JSON.parse(localStorage.getItem("authUser"))
            const UID = obj._id
            const USERNAME =
              obj.Firstname + " " + (obj.Lastname ? obj.Lastname : " ")
            CometChatWidget.createOrUpdateUser({
              uid: UID,
              name: USERNAME,
            }).then(user => {
              console.log("Initialization completed successfully")
              //You can now call login function.
              CometChatWidget.login({
                uid: UID,
              }).then(
                response => {
                  CometChatWidget.launch({
                    widgetID: "09132a41-5f98-4f95-8d36-4424d7a3155f",
                    docked: "true",
                    alignment: "right", //left or right
                    roundedCorners: "true",
                    height: "calc(100% - 180px)",
                    width: "400px",
                    defaultID: "", //default UID (user) or GUID (group) to show,
                    defaultType: "user", //user or group
                  })
                  // if (props.edata.chatroomOpen) {
                  if (false) {
                    setTimeout(() => {
                      CometChatWidget.openOrCloseChat(true)
                    }, 1000)
                  }
                },
                error => {
                  console.log("User login failed with error:", error)
                  //Check the reason for error and take appropriate action.
                }
              )
            })
          },
          error => {
            console.log("Initialization failed with error:", error)
            //Check the reason for error and take appropriate action.
          }
        )
      } else {
        if (document.getElementById("cometchat__widget")) {
          document.getElementById("cometchat__widget").style.display = "none"
        }
      }
    }, 1000)
    loader.classList.add("loader--hide")
    let scorr = []
    fetch(process.env.REACT_APP_ADMIN + "/getGratUserlist")
      .then(response => response.json())
      .then(async data => {
        // console.log("Alluserdata Array",data);
        let newData = await data.map(function (obj, Index) {
          obj.sno = Index + 1
          return obj
        })
        setAlluser(newData)
      })

    postData(process.env.REACT_APP_ADMIN + "/gratmail/getmygratitude", {
      recieverEmail: userObj.Email,
    }).then(async data => {
      // console.log("MyGrat data response",data);
      if (data.mssg === "success") {
        // console.log("recieved gratitude",data.docs); // JSON data parsed by `data.json()` call
        let newMyGratData = await data.docs.map(function (Gratobj, Index) {
          Gratobj.sno = Index + 1
          return Gratobj
        })
        setGratArr(newMyGratData)
      } else {
        console.log("Something went wrong", data.error)
      }
    })
  }, [])

  function downloadGrat(e) {
    e.preventDefault()
    var element = document.getElementById("#mygrat")
    console.log(document.getElementById("#mygrat"))
    // html2canvas(element).then(canvas => {
    //     document.getElementById("#previewImage").append(canvas);
    //     var anchorTag = document.createElement("a");
    //     document.body.appendChild(anchorTag);
    //     anchorTag.download="Report.png";
    //     anchorTag.href=canvas.toDataURL();
    //     anchorTag.target="_blank";
    //     anchorTag.click();
    // })
    htmlToImage
      .toPng(document.getElementById("my-node"))
      .then(function (dataUrl) {
        // console.log(dataUrl);
        download(dataUrl, "Certificate.png")
        // setmoda(!moda);
        // setsuccess_msg(true);//fake success message
      })
  }

  const toggle1 = tab => {
    if (ActiveTab !== tab) {
      setActiveTab(tab)
    }
  }

  async function gratitudeImage(e) {
    e.preventDefault()
    htmlToImage
      .toPng(document.getElementById("my-node"))
      .then(function (dataUrl) {
        // console.log(dataUrl);
        download(dataUrl, "Certificate.png")
        newImg = dataUrl
        setmoda(!moda)
        // setsuccess_msg(true);//fake success message
      })
  }

  async function postData(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    return response.json() // parses JSON response into native JavaScript objects
  }

  async function gratitudeMail(e) {
    e.preventDefault()

    try {
      //
      postData(process.env.REACT_APP_ADMIN + "/gratmail/savemessage", {
        messageBody: $("#subject").val(),
        recvEmail: recvEmail,
        recvName: recvName,
        userObj: userObj,
        templateURL: templateURL,
      }).then(data => {
        // console.log(data.Messages[0].Status); // JSON data parsed by `data.json()` call
        // setmoda(!moda); //close gratitude modal if success
        if (data.mssg === "success") {
          setgratstatus(true)
          console.log("su1", data.savedGrat) // JSON data parsed by `data.json()` call
          setsuccess_msg(true) //show success if mail sent
        } else {
          setsuccess_msg(true)
          console.log("Something went wrong", data.error)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        className="btn-custom"
        onClick={() => {
          // console.log(row.Email);
          setmoda(!moda)
          setrecvEmail(row.Email)
          setrecvName(row.Firstname)
        }}
      >
        Send
      </Button>
    )
  }

  const GratlinkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        className="btn-custom"
        onClick={() => {
          // console.log(row.Email);
          setGratmoda(!Gratmoda)
          setRecievedEmail(row.SenderEmail)
          setRecievedName(row.SenderFirstname)
          setRecievedMessage(row.Message)
          setrecvtemplateURL(row.TemplateUrl)
        }}
      >
        View
      </Button>
    )
  }

  const columns = [
    // {
    //     dataField: '_id',
    //     text: 'Unique Id',
    //     sort: false,
    // },
    {
      dataField: "sno",
      text: "Serial No.",
      sort: false,
    },

    {
      dataField: "Firstname",
      text: "Name",
      sort: false,
    },
    // {
    //     dataField: 'Lastname',
    //     text: 'Lastname',
    //     sort: false
    // },
    {
      dataField: "Email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "Email",
      text: "Send a note",
      formatter: linkFollow,
      sort: false,
    },
  ]

  const columnsMygrat = [
    {
      dataField: "sno",
      text: "Serial No.",
      sort: false,
    },

    {
      dataField: "SenderFirstname",
      text: "Sender Name",
      sort: false,
    },
    // {
    //     dataField: 'Lastname',
    //     text: 'Lastname',
    //     sort: false
    // },
    {
      dataField: "SenderEmail",
      text: "Sender Email",
      sort: false,
    },
    {
      dataField: "Email",
      text: "Recieved Note",
      formatter: GratlinkFollow,
      sort: false,
    },
  ]

  const defaultSorted = [
    {
      dataField: "total",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 12,
    totalSize: Alluser.length, // replace later with size(customers),
    custom: true,
  }

  const pageOptionsMy = {
    sizePerPage: 12,
    totalSize: GratArr.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "12", value: 12 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: Alluser.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div className="page-content">
        {success_msg ? (
          gratstatus ? (
            <SweetAlert
              title="Thank you!"
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
            >
              Your Gratitude card has been sent.
            </SweetAlert>
          ) : (
            <SweetAlert
              title="Fail"
              info
              confirmBtnBsStyle="info"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
            >
              Something went wrong , Please refresh and try again.
            </SweetAlert>
          )
        ) : null}
        <Modal
          size="lg"
          isOpen={moda}
          toggle={() => {
            setmoda(!moda)
          }}
        >
          <ModalHeader
            toggle={() => {
              setmoda(!moda)
            }}
          >
            Choose any card, customize the message below and send!
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col x-lg={6}>
                <div className="mb-3">
                  {/* <label htmlFor="name">Name</label> */}
                  <button
                    style={{ border: "none" }}
                    onClick={() => {
                      // settemplateURL("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                      //     encodeURIComponent(Alldata.data.template.image1) +
                      //     "?alt=media")
                      settemplateURL("1")
                      document.getElementById("1myID").style.borderStyle =
                        "solid"
                      document.getElementById("2myID").style.borderStyle =
                        "none"
                      // document.getElementById("3myID").style.borderStyle = "none";
                      document.getElementById("4myID").style.borderStyle =
                        "none"
                      document.getElementById("5myID").style.borderStyle =
                        "none"
                      // document.getElementById("6myID").style.borderStyle = "none";
                    }}
                  >
                    <img
                      id="1myID"
                      style={{
                        height: "110px",
                        width: "220px",
                        borderStyle: "solid",
                      }}
                      src={
                        // "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                        // encodeURIComponent(Alldata.data.template.image1) +
                        // "?alt=media"
                        "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Demo%2Fcard1.svg?alt=media"
                      }
                    />
                  </button>
                </div>
              </Col>
              <Col x-lg={6}>
                <div className="mb-3">
                  {/* <label htmlFor="name">Name</label> */}
                  <button
                    style={{ border: "none" }}
                    onClick={() => {
                      // settemplateURL("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                      //     encodeURIComponent(Alldata.data.template.image2) +
                      //     "?alt=media")
                      settemplateURL("2")
                      document.getElementById("2myID").style.borderStyle =
                        "solid"
                      document.getElementById("1myID").style.borderStyle =
                        "none"
                      // document.getElementById("3myID").style.borderStyle = "none";
                      document.getElementById("4myID").style.borderStyle =
                        "none"
                      document.getElementById("5myID").style.borderStyle =
                        "none"
                      // document.getElementById("6myID").style.borderStyle = "none";
                    }}
                  >
                    <img
                      id="2myID"
                      style={{
                        height: "110px",
                        width: "220px",
                      }}
                      src={
                        // "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                        // encodeURIComponent(Alldata.data.template.image2) +
                        // "?alt=media"
                        "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Demo%2Fcard2.svg?alt=media"
                      }
                    />
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col x-lg={4}>
                <div className="mb-3">
                  {/* <label htmlFor="name">Name</label> */}
                  <button
                    style={{ border: "none" }}
                    onClick={() => {
                      // settemplateURL("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                      //     encodeURIComponent(Alldata.data.template.image4) +
                      // "?alt=media")
                      settemplateURL("3")
                      document.getElementById("4myID").style.borderStyle =
                        "solid"
                      document.getElementById("1myID").style.borderStyle =
                        "none"
                      document.getElementById("2myID").style.borderStyle =
                        "none"
                      // document.getElementById("3myID").style.borderStyle = "none";
                      document.getElementById("5myID").style.borderStyle =
                        "none"
                      // document.getElementById("6myID").style.borderStyle = "none";
                    }}
                  >
                    <img
                      id="4myID"
                      style={{
                        height: "110px",
                        width: "220px",
                      }}
                      src={
                        // "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                        // encodeURIComponent(Alldata.data.template.image4) +
                        // "?alt=media"
                        "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Demo%2Fcard3.svg?alt=media"
                      }
                    />
                  </button>
                </div>
              </Col>
              <Col x-lg={4}>
                <div className="mb-3">
                  {/* <label htmlFor="name">Name</label> */}
                  <button
                    style={{ border: "none" }}
                    onClick={() => {
                      // settemplateURL("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                      //     encodeURIComponent(Alldata.data.template.image5) +
                      // "?alt=media")
                      settemplateURL("4")
                      document.getElementById("5myID").style.borderStyle =
                        "solid"
                      document.getElementById("1myID").style.borderStyle =
                        "none"
                      document.getElementById("2myID").style.borderStyle =
                        "none"
                      // document.getElementById("3myID").style.borderStyle = "none";
                      document.getElementById("4myID").style.borderStyle =
                        "none"
                      // document.getElementById("6myID").style.borderStyle = "none";
                    }}
                  >
                    <img
                      id="5myID"
                      style={{
                        height: "110px",
                        width: "220px",
                      }}
                      src={
                        // "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/" +
                        // encodeURIComponent(Alldata.data.template.image5) +
                        // "?alt=media"
                        "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/Demo%2FCard%204.svg?alt=media"
                      }
                    />
                  </button>
                </div>
              </Col>
            </Row>
            <form onSubmit={gratitudeMail}>
              <Row></Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <label htmlFor="subject">Message</label>
                    <textarea
                      className="form-control"
                      id="subject"
                      rows="3"
                      placeholder="Enter your message here (Maximum characters: 130)"
                      maxLength="130"
                    />
                    {/* <input type="text" value={messageMail} onChange={(e)=>e.setrecvEmail(e.target.value)}/> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="text-end">
                    <button
                      type="submit"
                      onClick={() => {
                        setmoda(!moda)
                        // setsuccess_msg(true)
                      }}
                      className="btn btn-primary"
                    >
                      Send
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>

        <Modal
          size="lg"
          isOpen={Gratmoda}
          toggle={() => {
            setGratmoda(!Gratmoda)
          }}
        >
          <ModalHeader
            toggle={() => {
              setGratmoda(!Gratmoda)
            }}
          >
            Click Download Button to Save the card.
          </ModalHeader>
          <ModalBody>
            <form
            // onSubmit={gratitudeMail}
            >
              <Row>
                <div id="my-node">
                  {/* <img src={a1} /> */}
                  <GratitudeTemplate
                    templateURL={recvtemplateURL}
                    RecievedEmail={RecievedEmail}
                    RecievedName={RecievedName}
                    templatemessage={RecievedMessage}
                  ></GratitudeTemplate>
                </div>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="text-end mt-2">
                    <button
                      type="submit"
                      onClick={e => {
                        // setmoda(!moda);
                        // setsuccess_msg(true)
                        downloadGrat(e)
                      }}
                      className="btn btn-primary"
                    >
                      Download
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
            <div id="#previewImage"></div>
          </ModalBody>
        </Modal>
        <div className="container-fluid">
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "Send Gratitude",
                })}
                onClick={() => {
                  toggle1("Send Gratitude")
                  setactiveTabContent("1")
                }}
              >
                Send Gratitude
              </NavLink>
              <div id="1"></div>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: ActiveTab === "My Gratitude",
                })}
                onClick={() => {
                  toggle1("My Gratitude")
                  setactiveTabContent("2")
                }}
              >
                My Gratitude
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTabContent} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h1" style={{ color: "#000" }}>
                        Search a colleague by name and send them a Gratitude
                        card.
                      </CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="key"
                        columns={columns}
                        data={Alluser}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columns}
                            data={Alluser}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                        />
                                        </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <CardTitle className="h1" style={{ color: "#000" }}>
                        Search a colleague by name and see what they got to say.
                      </CardTitle>
                      <p className="card-title-desc"> </p>

                      <PaginationProvider
                        pagination={paginationFactory(pageOptionsMy)}
                        keyField="key"
                        columns={columnsMygrat}
                        data={GratArr}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="key"
                            columns={columnsMygrat}
                            data={GratArr}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"key"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        // defaultSorted={defaultSorted}
                                        // selectRow={selectRow}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    {/* <div className="d-inline">
                                        <SizePerPageDropdownStandalone
                                            {...paginationProps}
                                        />
                                        </div> */}
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Gratitude
