import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
// import {subscribeUser} from "./subscription"
import store from "./store"
import { isMacOs, isMobile } from "react-device-detect"
import DeviceOrientation, { Orientation } from "react-screen-orientation"
import { Container } from "reactstrap"
// subscribeUser()
const loader = document.querySelector(".loader")

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide")

const hideLoader = () => loader.classList.add("loader--hide")

const app = (
  <Provider store={store}>
    <BrowserRouter>
      {isMacOs ? (
        <App hideLoader={hideLoader} showLoader={showLoader} />
      ) : (
        (isMobile && (window.location.pathname=="/login" || window.location.pathname=="/home" || (localStorage.getItem("authUser") ? false: window.location.pathname=="/"))) ? 
        <DeviceOrientation lockOrientation={"portrait"}>
          {/* Will only be in DOM in landscape */}
          <Orientation orientation="portrait" alwaysRender={false}>
          <App hideLoader={hideLoader} showLoader={showLoader} />
          </Orientation>
          <Orientation orientation="landscape" alwaysRender={false}>
            <Container style={{ padding: 0, position: "relative" }} fluid>
              <img
                width="100%"
                height="100%"
                alt="clickable"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/image1%2Fportrait-orientation.png?alt=media"
                }
                style={{
                  zIndex: 9999999999999999999,
                  position: "relative",
                  display: "block",
                  backgroundColor: "white",
                }}
                onLoad={() => {
                  loader.classList.add("loader--hide")
                }}
              />
            </Container>
          </Orientation>
        </DeviceOrientation>
        :
        <DeviceOrientation lockOrientation={"landscape"}>
          {/* Will only be in DOM in landscape */}
          <Orientation orientation="landscape" alwaysRender={false}>
            <App hideLoader={hideLoader} showLoader={showLoader} />
          </Orientation>
          <Orientation orientation="portrait" alwaysRender={false}>
            <Container style={{ padding: 0, position: "relative" }} fluid>
              <img
                width="100%"
                height="100%"
                alt="clickable"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/image1%2Frotate.png?alt=media"
                }
                style={{
                  zIndex: 9999999999999999999,
                  position: "relative",
                  display: "block",
                  backgroundColor: "black",
                }}
                onLoad={() => {
                  loader.classList.add("loader--hide")
                }}
              />
            </Container>
          </Orientation>
        </DeviceOrientation>
      )}
    </BrowserRouter>
  </Provider>
)
fetch(process.env.REACT_APP_ADMIN + "/getJD")
  .then(response => response.json())
  .then(data => {
    localStorage.setItem("dataJ", JSON.stringify(data))
  })
  .then(() => {
    ReactDOM.render(app, document.getElementById("root"))
  })

serviceWorker.unregister()

// serviceWorker.register()
