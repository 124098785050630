import PropTypes from "prop-types"
import React, { useState } from "react"

import { connect } from "react-redux"
import "react-drawer/lib/react-drawer.css"

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"

//i18n
import { withTranslation } from "react-i18next"

// Redux Store
import {
  openAnn
} from "../../store/actions"

const Header = props => {
  const { ann, onOpenAnn } = props
  
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)
  const [soundOn, setsoundOn] = useState(false)
  const [currentLocation, setcurrentLocation] = useState(window.location.pathname)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle("vertical-collpsed")
    body.classList.toggle("sidebar-enable")
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div className="d-flex">
          {currentLocation==="/page/lobby" ? 
                    soundOn ?
                      <div className="dropdown  d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={()=>{
                        var video=document.getElementById("vid-2");
                        video.muted = !video.muted;
                        setsoundOn(!soundOn);}}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-volume-high" />
                {/* //<span className="badge bg-danger rounded-pill">3</span> */}
              </button>
              </div>
              :
              <div className="dropdown  d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={()=>{
                        var video=document.getElementById("vid-2");
                        video.muted = !video.muted;
                        setsoundOn(!soundOn);
                        }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-volume-off" />
                {/* //<span className="badge bg-danger rounded-pill">3</span> */}
              </button>
              </div>
              :
              null
              }
          <div className="dropdown  d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={()=>{window.location.href="/mylibrary"}}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="bx bxs-briefcase" />
                {/* //<span className="badge bg-danger rounded-pill">3</span> */}
              </button>
            </div>
          <div className="dropdown  d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={onOpenAnn}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="bx bx-bell bx-tada" />
                {/* <span className="badge bg-danger rounded-pill">3</span> */}
              </button>
            </div>
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>
            
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  ann: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  openAnn: PropTypes.func,
}

const mapStatetoProps = (state,agenda) => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  const {ann}=agenda
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType,ann }
}

const mapDispatchToProps = dispatch => ({
  onOpenAnn: () => dispatch(openAnn()),
})

export default connect(mapStatetoProps,  mapDispatchToProps)(withTranslation()(Header))
