import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  objct,
  isAuthProtected,
  path,
  ...rest
}) => (
  <>
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/home", state: { from: props.location } }}
            />
          )
        }

        return path == "/page/auditorium" || path == "/profile-builder" ? (
          <Component {...objct} />
        ) : (
          <Layout>
            <Component {...objct} />
          </Layout>
        )
      }}
    />
  </>
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  objct: PropTypes.any,
  path: PropTypes.any,
}

export default Authmiddleware
