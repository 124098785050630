import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"
import profileBuilder from "../pages/Authentication/profile-builder"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Home from "../pages/Authentication/home"
import Logout from "../pages/Authentication/Logout"
import AuthCheck from "../pages/Authentication/authCheck"
import AuthError from "../pages/Authentication/authError"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// import Notifications from "../pages/notifications/index"
import Speakers from "pages/speakers"
import Leaderboard from "pages/Leaderboard"
import Gratitude from "pages/Gratitude/Gratitude"
import ActivityFeed from "pages/ActivityFeed/ActivityFeed"
import Mylibrary from "pages/MyLibrary/Mylibrary"
import Networking from "pages/networkingZone"

//Blog
import BlogList from "../pages/Blog/BlogList/index"
import BlogGrid from "../pages/Blog/BlogGrid/index"
import BlogDetails from "../pages/Blog/BlogDetails"

const userRoutes = [
  { path: "/dashboard", objct: {}, component: Dashboard },

  // { path: "/sendnotification", objct:{},component: Notifications },

  // //profile
  { path: "/profile", objct: {}, component: UserProfile },

  // //profile-builder
  { path: "/profile-builder", objct: {}, component: profileBuilder },

  // //speakers
  { path: "/speakers", objct: {}, component: Speakers },

  // //leaderboard
  { path: "/leaderboard", objct: {}, component: Leaderboard },

  // //Networking
  { path: "/networking", objct: {}, component: Networking },

  // //Gratitude
  { path: "/Grantitude", objct: {}, component: Gratitude },

  // //Mylibrary
  { path: "/mylibrary", objct: {}, component: Mylibrary },

  // //ActivityFeed
  { path: "/ActivityFeed", objct: {}, component: ActivityFeed },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/page/auditorium" /> },

  //Blog
  { path: "/blog-list", component: BlogList },
  { path: "/blog-grid", component: BlogGrid },
  { path: "/blog-details", component: BlogDetails },
]

const authRoutes = [
  { path: "/logout", objct: {}, component: Logout },
  { path: "/login", objct: {}, component: Login },
  { path: "/home", objct: {}, component: Home },
  { path: "/login/check", objct: {}, component: AuthCheck },
  { path: "/login/fail", objct: {}, component: AuthError },
]

export { userRoutes, authRoutes }
